import { Coupon } from "./../types/coupon";
import ProductService from "@/services/product-service";
import { FeedbackRequestType } from "@/types/feedback";
import { Product, ProductNameByType, ProductType } from "@/types/product";
import { Track } from "@/types/track";
import { handleError } from "@/utils/error";
import { defineStore } from "pinia";
import Stripe from "stripe";
import { User } from "../types/user";
import { getGAVariables } from "@/utils/helpers";
import { AnalyticsVars } from "@/types/analytics";

export interface FeedbackProduct {
  _id?: string;
  proId: string;
  type: ProductType;
  price: number;
  isAvailable: boolean;
  title: ProductNameByType;
}

export interface Call {
  callTime?: Date;
  timezone: string;
  requestedTimestamps: (Date | number)[];
}

export interface FeedbackDetails {
  producedByOwn?: boolean;
  feedbackType?: FeedbackRequestType;
  songReference?: string;
  artistRole?: string;
  socialProfile?: string;
  topic?: string;
  stages?: string[];
}

export interface ProductRequest {
  mainQuestion: string;
  extraQuestion?: string;
  trackId?: string;
  track?: Track;
  type: ProductType;
  feedback?: FeedbackDetails;
  call?: Call;
  createdAt?: Date;
  phone?: string;
  _id?: string;
}

interface ProductsState {
  feedbackTrack?: Track;
  purchaseProduct?: Partial<Product>;
  feedbackRequest: Partial<ProductRequest>;
  feedbackRequestProduct: { songPreviewUrl?: string };
  paymentDetails: { cardUsed: string; couponUsed: string; totalPaid: number };
  pro: Partial<User>;
}
const productsService = new ProductService("products");
export const useProductsStore = defineStore("products", {
  state: (): ProductsState => ({
    feedbackTrack: undefined,
    purchaseProduct: undefined,
    feedbackRequest: {},
    feedbackRequestProduct: {},
    paymentDetails: { cardUsed: "", couponUsed: "", totalPaid: 0 },
    pro: {},
  }),
  actions: {
    clearAll() {
      this.feedbackTrack = undefined;
      this.purchaseProduct = {};
      this.feedbackRequest = {};
      this.feedbackRequestProduct = {};
      this.paymentDetails = { cardUsed: "", couponUsed: "", totalPaid: 0 };

      localStorage.removeItem("products");
      localStorage.removeItem("feedbackRequestSongUrl");
    },
    setFeedbackSongPreviewUrl(url: string) {
      localStorage.setItem("feedbackRequestSongUrl", url || "");
      this.feedbackRequestProduct = { songPreviewUrl: url };
    },
    async getProductsByProId(proId: string) {
      // this is the only way I could make it work, this.productsService is a proxy and it throws an error on headers.set
      return await productsService.list(proId);
    },
    setFeedbackTrack(data: Track) {
      this.feedbackTrack = data;
      this.feedbackRequest.trackId = data._id;
    },
    removeFeedbackTrack() {
      this.feedbackTrack = undefined;
    },
    setFeedbackRequest(data: ProductRequest) {
      this.feedbackRequest = data;
    },
    setCall(data: Call) {
      this.feedbackRequest = {
        ...this.feedbackRequest,
        call: data,
      };
    },
    setPurchaseProduct(data?: Product) {
      this.purchaseProduct = data ?? undefined;
    },
    setPurchaseProductCheckoutId(checkoutId: string) {
      this.purchaseProduct = { ...this.purchaseProduct, checkoutId };
    },
    setSelectedPro(pro: User) {
      this.pro = pro;
    },
    setProductType(productType: ProductType) {
      this.feedbackRequest.type = productType;
    },
    setMainQuestion(mainQuestion: string) {
      this.feedbackRequest.mainQuestion = mainQuestion;
    },
    setExtraQuestion(extraQuestion: string) {
      this.feedbackRequest.extraQuestion = extraQuestion;
    },
    setTopic(topic: string) {
      this.feedbackRequest.feedback = {
        ...this.feedbackRequest.feedback,
        topic,
        feedbackType: topic as FeedbackRequestType,
      };
    },
    setStages(stages: string[]) {
      this.feedbackRequest.feedback = {
        ...this.feedbackRequest.feedback,
        stages,
      };
    },
    setSocialProfile(socialProfile: string) {
      this.feedbackRequest.feedback = {
        ...this.feedbackRequest.feedback,
        socialProfile,
      };
    },
    setArtistRole(artistRole: string) {
      this.feedbackRequest.feedback = {
        ...this.feedbackRequest.feedback,
        artistRole,
      };
    },
    setPhone(phone: string) {
      this.feedbackRequest.phone = phone;
    },
    async getPaymentDetails(
      id: string,
    ): Promise<{ price: number; total: number; serviceFee: number }> {
      try {
        return await productsService.getPaymentDetails(id);
      } catch (error) {
        throw handleError(error);
      }
    },
    async authorizePayment(
      productId: string,
      token: string,
      coupon: Coupon,
    ): Promise<Stripe.PaymentIntent> {
      try {
        if (this.purchaseProduct) {
          const analytics = await getGAVariables();
          return productsService.authorizePayment(
            productId,
            token,
            {
              ...this.feedbackRequest,
              songUrl: this.feedbackRequestProduct.songPreviewUrl,
              couponCode: coupon.coupon,
              analytics,
            } as ProductRequest & { songUrl: string; analytics: AnalyticsVars },
            this.purchaseProduct.checkoutId || "",
          );
        } else {
          throw handleError("No product selected");
        }
      } catch (error) {
        throw handleError(error);
      }
    },
    async createPaymentIntent(
      productId: string,
      coupon: Coupon,
    ): Promise<string> {
      try {
        const analytics = await getGAVariables();
        return productsService.createPaymentIntent(productId, {
          ...this.feedbackRequest,
          songUrl: this.feedbackRequestProduct.songPreviewUrl,
          couponCode: coupon.coupon,
          analytics,
        } as ProductRequest & { songUrl: string; analytics: AnalyticsVars });
      } catch (e) {
        throw handleError(e);
      }
    },
    async submitFreeRequest(productId: string): Promise<ProductRequest> {
      try {
        const service = new ProductService("products");
        if (!this.feedbackRequest.mainQuestion)
          this.feedbackRequest.mainQuestion = " ";
        const request = await service.submitFreeRequest(
          productId,
          this.feedbackRequest as ProductRequest,
        );

        return request as any;
      } catch (error) {
        throw handleError(error);
      }
    },
    async getProductTaxes(
      productId: string,
      country: string,
      zipCode?: string,
    ) {
      try {
        const service = new ProductService("products");
        return service.getProductTaxes(productId, country, zipCode);
      } catch (e) {
        throw handleError(e);
      }
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});
